




































































































































































































































































































































































































import { openDupeAccountDlg } from '@components/view/LoginDuplicateAccount.vue'
import AstButton from '@components/Button.vue'
import AstCheckbox from '@components/Checkbox.vue'
import ImageFileInput from '@components/ImageFileInput.vue'
import { Annex } from '@services/api'
import { ValidatorMixin } from '@mixins/validator'
import { userData } from '@services/userData'
import { showAlert } from '../dialogs/MessageDlg.vue'

export default {
	name: 'UserProfileForm',
	components: {
		AstButton,
		AstCheckbox,
		ImageFileInput,
	},
	mixins: [ValidatorMixin],
	props: {
		currentUser: {
			type: Object,
			default: () => undefined,
		},
		isSubmitting: {
			type: Boolean,
			required: true,
		},
		isNewUser: {
			type: Boolean,
			default: false,
		},
		isSettingDefaults: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			roles: [],
			permissions: [],
			regions: [],
			form: {
				userId: null,
				permissions: [],
				regions: [],
				title: '',
				firstName: '',
				lastName: '',
				suffix: '',
				degree: '',
				accreditationNumber: '',
				organizationName: '',
				email: '',
				website: '',
				phoneType: 0,
				phone: '',
				address: '',
				city: '',
				state: '',
				postalCode: '',
				country: '',
				username: '',
				password: '',
				signatureFile: null,
				logoFile: null,
				clearSignature: false,
				clearLogo: false,
			},
			confirmPassword: '',
			isChanged: false,
			signatureUrl: null,
			logoUrl: null,
			isConsultant: false,
			isNonConsultantOmniUser: true,
			userId: null,
		}
	},
	computed: {
		role: {
			get() {
				let map = {}
				this.form.permissions.forEach(p => (map[p] = true))
				let total = this.form.permissions.length
				let role = this.roles.find(r => r.permissions.length === total && r.permissions.every(p => map[p]))
				return role && role.name
			},
			set(newValue) {
				let role = this.roles.find(r => r.name === newValue)
				this.form.permissions = role.permissions.slice(0)
			},
		},
		enforcePasswordComplexity() {
			if (!this.signedIn) return true // Community
			else  {
				const clinicFeatures = this.$store.getters.activeClinic?.optionalFeatures || []
				return clinicFeatures.includes('EnforcePasswordComplexity')
			}
		},
		passwordValidations() {
			const validations = []
			if (this.isNewUser || this.confirmPassword.length) validations.push('required')
			if (this.enforcePasswordComplexity) validations.push(`complexity:${this.form.username}`)
			return validations.join('|')
		},
		passwordComplexityFailed() {
			return this.form.password.length && this.hasError('password')
		},
		passwordsDoNotMatch() {
			return this.hasError('confirmPassword')
		},
		signedIn() {
			return userData.claims.userId
		},
		regionalPermission() {
			return this.permissions.find(p => p.name === 'Regional Permission')
		},
		isRegionalPermissionChecked() {
			return this.form.permissions.includes(this.regionalPermission.id)
		},
	},
	watch: {
		form: {
			handler(newValue, oldValue) {
				// Set isChanged once form is changed, ignoring initial population
				if (oldValue.username || this.isSettingDefaults) this.isChanged = true
			},
			deep: true,
		},
	},
	mounted() {
		if (this.isNewUser) {
			setTimeout(() => {
				this.form.username = ''
				this.form.password = ''
			}, 1000)
		}
		if (this.currentUser) this.form = { ...this.form, ...this.currentUser }
		this.validator.validate()
	},
	async created() {
		if (this.isSettingDefaults) this.validator.pause()

		if (userData.permissions.userAdministration) {
			const promises = [
				userData.getPermissionMeta().then(r => {
					if (!r) return
					this.permissions = r.permissions.filter(p => p.name !== 'Review Submission Viewer' && p.name !== 'Review Submission Submitter' && p.name !== 'Review Submission Approver')
					this.roles = r.roles
				}),
				userData.getRegions().then(r => {
					this.regions = r
				}),
			]
			await Promise.all(promises)
		}

		const base = Annex.defaults.baseURL
		if (this.userId) {
			let cc = userData.claims.activeClinicCode
			let isConsultant = this.isConsultant
			let rand = Math.random()

			this.signatureUrl = `${base}/logo.aspx?ClinicCode=${cc}&Type=signature&UserId=${this.userId}&invalidate=${rand}`

			if (isConsultant) {
				this.logoUrl = `${base}/logo.aspx?ClinicCode=${cc}&Type=logo&ConsultantId=${this.userId}&invalidate=${rand}`
			}
		}
		if (this.currentUser) {
			this.isConsultant = this.currentUser.isConsultant
			this.userId = this.currentUser.userId
		}
		this.isNonConsultantOmniUser =
			userData.claims.userId && !(this.currentUser && this.currentUser.isCommunity) && !this.isConsultant
	},
	methods: {
		async checkExistingEmail(email) {
			// On check if registering new user and email has changed
			if (this.isNewUser && email !== this.lastEmailChecked) {
				if (!this.hasError('email')) {
					// Fetch duplicate accounts with matching email
					let dupes = await userData.getCommunityUserAccounts(email)
					if (dupes.length > 0) {
						if (userData.permissions.userAdministration) {
							showAlert('Email is already associated to these accounts: ' + dupes.map(d => d.clinicName))
						} else {
							let response = await openDupeAccountDlg(dupes)
							if (response) {
								this.$emit('cancel')
							}
						}
					}
				}
				// Save the last checked email to prevent future unnecessary fetches
				this.lastEmailChecked = email
			}
		},
		submitForm() {
			if (this.anyErrors) return
			this.$emit('submit', this.form)
		},
		clearLogo() {
			this.logoUrl = null
			this.form.logoFile = null
			this.form.clearLogo = true
			this.isChanged = true
		},
		clearSignature() {
			this.signatureUrl = null
			this.form.signatureFile = null
			this.form.clearSignature = true
			this.isChanged = true
		},
		selectSignatureFile({ target }) {
			if (!target.files.length) return
			let file = target.files[0]
			this.form.signatureFile = file
			const reader = new FileReader()
			reader.addEventListener(
				'load',
				() => {
					this.signatureUrl = reader.result
					this.form.clearSignature = false
				},
				false
			)
			reader.readAsDataURL(file)
		},
		selectLogoFile({ target }) {
			if (!target.files.length) return
			let file = target.files[0]
			this.form.logoFile = file
			const reader = new FileReader()
			reader.addEventListener(
				'load',
				() => {
					this.logoUrl = reader.result
					this.form.clearLogo = false
				},
				false
			)
			reader.readAsDataURL(file)
		},
	},
}
