<template>
	<modal-wrapper @close="closeModal">
		<div class="modal">
			<div class="modal-header">
				<h4>Existing Account Found</h4>
				<ast-button class="close" @click.native="closeModal">
					<svg-icon icon="close" />
				</ast-button>
			</div>
			<div class="modal-body">
				<div class="duplicate-message is-danger">
					<svg-icon icon="exclamation-circle" />
					<p>
						This email address is already registered with the following
						{{ accountTypes }} account{{ duplicateAccounts.length === 1 ? '' : 's' }}:
					</p>
				</div>
				<ul class="duplicate-accounts is-danger">
					<li v-for="account in duplicateAccounts" :key="account.clinicName">
						<div class="duplicate-account">
							<span>{{ account.clinicName }}</span>
						</div>
					</li>
				</ul>
				<p class="duplicate-prompt">
					Would you like to login to your existing account instead of creating a new Community user?
				</p>
				<div class="duplicate-footer">
					<button class="btn btn-primary" @click="loginWithExisting()">
						Yes
					</button>
					<button autofocus class="btn btn-default" @click="closeModal()">
						No
					</button>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import AstButton from '@components/Button'
import ModalDialog from '@components/ModalDialog.vue'
import { dlg } from '@utils/dlgUtils'

const modal = {
	name: 'LoginDuplicateAccount',
	components: {
		ModalWrapper,
		AstButton,
	},
	props: {
		duplicateAccounts: {
			type: Array,
			required: true,
		},
	},
	computed: {
		accountTypes() {
			const types = []
			if (this.duplicateAccounts.some(a => a.communityClinicCode)) {
				types.push('Community')
			}
			if (this.duplicateAccounts.some(a => !a.communityClinicCode)) {
				types.push('Keystone Omni')
			}
			return types.join(' and ')
		},
	},
	methods: {
		loginWithExisting() {
			dlg.close(this, true, true)
		},
		closeModal() {
			dlg.close(this, true, false)
		},
	},
}

export default modal

export function openDupeAccountDlg(duplicateAccounts) {
	return dlg.open(modal, { duplicateAccounts })
}
</script>

<style lang="scss" scoped>
.modal {
	background: var(--secondary-bg);
	border: var(--secondary-border);
	position: relative;
	overflow-x: hidden;
	max-height: 90vh;
}
.modal-header {
	padding: 20px 10px 0 10px;
	h4 {
		text-align: center;
		font-weight: bold;
	}
	.close {
		position: absolute;
		right: 10px;
		top: 10px;
		& .icon {
			margin-right: 0;
		}
	}
}
.modal-body {
	padding: 20px;
	width: 450px;
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	align-content: space-between;
	overflow: auto;
}

.duplicate-message {
	display: flex;
	& .icon {
		margin-top: 2px;
	}
	& p {
		margin-left: 6px;
	}
}
.duplicate-accounts {
	margin: 10px 0 0 20px;
	& .duplicate-account {
		display: flex;
		justify-content: space-between;
		margin-top: 5px;
	}
}
.duplicate-prompt {
	margin: 15px 20px 20px 20px;
}
.duplicate-footer {
	display: flex;
	& .btn {
		width: 80px;
		&:not(:first-child) {
			margin-left: 10px;
		}
	}
}
</style>
