<template>
	<div class="image-uploader" :class="{ 'is-dragging': isDragging, selectable: selectable, selected: selected }">
		<template v-if="hasImage">
			<img :src="url" @error="hasImage = false" @click="$emit('select', id)" />
			<button type="button" class="delete-button" @click="clearImage">
				<svg-icon icon="close" />
			</button>
		</template>
		<div v-else-if="isNoSelection" class="noImageSelected" @click="$emit('select', null)">
			No Image Selected
		</div>
		<template v-else-if="!hasImage">
			<span style="text-align: center;">
				<svg-icon icon="file-upload" style="font-size: 3em; margin-bottom: 8px; opacity: 0.3;" /><br />
				Drop image file here or
				<a style="text-decoration: underline;">browse</a></span
			>
			<input
				v-if="!hasImage"
				ref="fileInput"
				type="file"
				accept=".png,.jpeg,.jpg,.bmp,.tiff"
				@change="$emit('change', $event)"
			/>
		</template>

		<svg-icon v-if="selected" class="check" icon="check" />
	</div>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
export default {
	name: 'ImageFileInput',
	components: { SvgIcon },
	props: {
		url: {
			type: String,
			default: null,
		},
		isNoSelection: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			hasImage: true,
			isDragging: false,
		}
	},
	watch: {
		url: {
			handler(url) {
				this.hasImage = !!url
				this.isDragging = false
			},
			immediate: true,
		},
	},
	mounted() {
		this.toggleDragListeners(true)
	},
	methods: {
		toggleDragListeners(isAdding) {
			let node = this.$el.parentNode
			if (node) {
				const addOrRemove = isAdding ? 'addEventListener' : 'removeEventListener'
				node[addOrRemove]('dragover', this.setIsDragging)
				node[addOrRemove]('dragenter', this.setIsDragging)
				node[addOrRemove]('dragend', this.setIsNotDragging)
				node[addOrRemove]('dragleave', this.setIsNotDragging)
			}
			if (isAdding)
				this.$once('hook:beforeDestroy', () => {
					this.toggleDragListeners(false)
				})
		},
		setIsDragging(e) {
			this.isDragging = true
		},
		setIsNotDragging(e) {
			this.isDragging = false
		},
		clearImage() {
			this.hasImage = false
			this.$emit('clear', this.id)
		},
	},
}
</script>

<style lang="scss">
.image-uploader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background: var(--secondary-bg);
	width: 200px;
	padding: 8px;
	margin-top: 4px;
	height: 200px;
	border: 1px solid var(--secondary-border);

	&.selectable:not(.selected):hover {
		border-color: var(--button-primary-bg-hover);
	}

	&.selected {
		border-color: var(--checkbox-checked-border);
	}

	&.is-dragging {
		background: var(--tertiary-bg);
	}
	img {
		width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	&.selectable {
		img {
			cursor: pointer;
		}
	}

	input[type='file'] {
		opacity: 0;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		cursor: pointer;
	}
	.delete-button {
		position: absolute;
		top: 4px;
		right: 4px;
		background: var(--button-default-bg);
		color: var(--button-default-color);
		border: 0;
		outline: 0;
		width: 30px;
		height: 30px;
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			background: var(--button-danger-bg);
			color: var(--button-danger-color);
		}
	}

	.noImageSelected {
		width: 100%;
		height: 100%;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.check {
		position: absolute;
		top: 4px;
		left: 4px;
	}
}
</style>
